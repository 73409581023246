import {
  user,
  postLoginRedirectKey,
  type LoginManager,
  type OidcClient,
  type Bus,
  type UseLogin,
  type GetClientInfo,
  type BaseRoute,
  type LogAnalyticsEvent,
  type AuthPromise,
  type FullModeInstallOptions,
  type LoginFunctionParams,
  type CallbackActionType,
} from '../install.ts'
import type { VuexOidcErrorPayload, VuexOidcState } from 'vuex-oidc'
import type { User as OidcUser } from 'oidc-client-ts'
import { sentryException } from '../sentry.js'
import getOidcSettings from '../config.js'
import storage from '@grantstreet/psc-js/utils/safe-local-storage.js'
import Vuex, { Store } from 'vuex'
import User from '../models/User.ts'
import Vue from 'vue'
import { vuexOidcCreateStoreModule, VuexOidcClientSettings } from 'vuex-oidc'

Vue.use(Vuex)

/**
 * @grantstreet/psc-vue/utils/i18n.ts>loadTranslations() is a peer dependency of
 * @grantstreet/login. You should call it in your own app before using any of
 * the login components.
 */

/**
 * A manager for the "full" login mode, which supports a full app login
 * experience (redirecting the user to a login page, handling the callback, and
 * handling silent token renewals).
 */
export default class FullLoginManager implements LoginManager {
  #store: Store<VuexOidcState>
  #oidcClient: OidcClient
  #bus: Bus
  #useLogin: UseLogin
  #getClientInfo: GetClientInfo
  #baseRoute?: BaseRoute
  #logAnalyticsEvent?: LogAnalyticsEvent

  /**
   * This will resolve once we know whether the user is logged in or not. This
   * is determined by vuex-oidc code in a hidden iframe.
   */
  authPromise: AuthPromise

  #resolveAuthPromise?: () => void

  constructor (opts: FullModeInstallOptions) {
    this.#oidcClient = opts.oidcClient
    this.#bus = opts.bus
    this.#useLogin = opts.useLogin
    this.#getClientInfo = opts.getClientInfo
    this.#baseRoute = opts.baseRoute
    this.#logAnalyticsEvent = opts.logAnalyticsEvent

    this.authPromise = new Promise(resolve => {
      this.#resolveAuthPromise = resolve
    })

    this.#store = new Vuex.Store({
      modules: {
        oidcStore: vuexOidcCreateStoreModule(
          getOidcSettings({
            baseRoute: this.#baseRoute,
            oidcClient: this.#oidcClient,
          }) as VuexOidcClientSettings,
          {
            dispatchEventsOnWindow: false,
          },
          {
            userLoaded: (oidcUser: OidcUser) => {
              // Update the global `user` object fields based on the new
              // OidcUser
              this.#updateUserFields(oidcUser)

              // Now emit the global `user` object (not the raw OidcUser)
              this.#bus.$emit('login.userLoaded', user)
            },
            userUnloaded: () => {
              this.#bus.$emit('login.userUnloaded')
              this.#clearUser()
            },
            userSignedOut: () => {
              this.#bus.$emit('login.userSignedOut')
              this.#clearUser()
            },
            accessTokenExpiring: () => {
              this.#bus.$emit('login.accessTokenExpiring')
            },
            accessTokenExpired: () => {
              this.#bus.$emit('login.accessTokenExpired')
              this.#clearUser()
            },
            oidcError: (error?: VuexOidcErrorPayload) => {
              this.#bus.$emit('login.error', error)
            },
            silentRenewError: (error?: VuexOidcErrorPayload) => {
              this.#bus.$emit('login.silentRenewError', error)
            },
            automaticSilentRenewError: (error?: VuexOidcErrorPayload) => {
              this.#bus.$emit('login.automaticSilentRenewError', error)
            },
          },
        ),
      },
    })

    this.#installLogin()
  }

  /**
   * Installs the Login module. Initialization of the login session is async,
   * and will resolve the "authPromise" above when complete.
   */
  #installLogin () {
    this.#bus.$on('payhub.loaded', () => this.handleCallbackActions())

    this.#bus.$on('login.accessTokenExpired', async () => {
      // To unload all user-specific data, just reload the page.
      // It seems risky to try to unload it from each module,
      // although that seem cleaner in principle. This should
      // happen rarely, regardless.
      if (this.#store.getters.oidcUser) {
        await this.#store.commit('removeOidcUser')

        // .reload() doesn't work properly with history in FF
        // eslint-disable-next-line no-self-assign
        window.location.href = window.location.href
      }
    })

    // Need to force a reload of the cached Login Service profile after
    // making a change.
    this.#bus.$on('payhub.userMetadataChanged', async (args: {
      // Allow `any` args here until we add types to getOidcUser
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [key: string]: any
    }) => {
      const oidcUser = await this.#store.dispatch('getOidcUser')

      for (const k of Object.keys(args)) {
        oidcUser.profile[k] = args[k]
      }

      await this.#store.dispatch('storeOidcUser', oidcUser)

      this.#updateUserFields(oidcUser)
    })

    // GA events:
    this.#bus.$on('login.userLoaded', (user?: User) => {
      if (user?.id) {
        this.#logAnalyticsEvent?.(user.id, {
          'event_category': 'Login Success',
        })
      }
    })
    this.#bus.$on('login.userSignedOut', () => {
      this.#logAnalyticsEvent?.('Logout', {
        'event_category': 'Logout',
      })
    })
    this.#bus.$on('login.error', (error?: VuexOidcErrorPayload) => {
      if (error?.error) {
        this.#logAnalyticsEvent?.(error.error, {
          'event_category': 'Login Failure',
        })
      }
    })

    // Errors:
    this.#bus.$on('login.oidcError', (error?: VuexOidcErrorPayload) => {
      this.#handleOidcError(error)
    })
    this.#bus.$on('login.silentRenewError', (error?: VuexOidcErrorPayload) => {
      this.#handleOidcError(error)
    })
    this.#bus.$on('login.automaticSilentRenewError', (error?: VuexOidcErrorPayload) => {
      this.#handleOidcError(error)
    })

    // Initialization (async - must resolveAuthPromise())
    this.#initLogin()
      .then(() => this.#resolveAuthPromise?.())
  }

  /**
   * Updates the user object exported by this file with the new oidcUser's
   * fields. This will be called whenever the user's details change, e.g.,
   * because they are first loaded.
   */
  #updateUserFields (oidcUser: OidcUser) {
    user.id = oidcUser.profile.sub
    user.email = oidcUser.profile.email
    user.name = oidcUser.profile.name
    user.givenName = oidcUser.profile.given_name
    user.familyName = oidcUser.profile.family_name
    user.adminClient = oidcUser.profile.admin_client as (string | undefined)

    const contact = oidcUser.profile.contact_preference
    user.contactPreference = contact === 'email' || contact === 'sms'
      ? contact
      : undefined

    user.phone = (
      oidcUser.profile['https://pay-hub.net/phone'] ||
      oidcUser.profile['https://govhub.com/phone']
    ) as (string | undefined)

    const language = oidcUser.profile['https://pay-hub.net/language'] ||
      oidcUser.profile['https://govhub.com/language']
    user.language = language === 'en' || language === 'es'
      ? language
      : undefined

    user.getAccessToken = () => oidcUser.access_token as (string | undefined)
  }

  /**
   * Resets the user back to anonymous status.
   */
  #clearUser () {
    user.reset()
  }

  #handleOidcError (error?: VuexOidcErrorPayload): void {
    // This is never a user-visible error, but if something unexpected
    // blew up, we want the Sentry notification about it.

    if (!error) {
      return
    }

    if (error.error === 'invalid_grant') {
      // this error is thrown if a user's refresh token has expired. Ingore.
      // unfortunately, the error does not include any more details to specify to ignore
      // only refresh token failures.
      return
    }

    if (error.toString().match(/Login required|End-User authentication is required/)) {
      // The "Login required" error is normal processing when the user isn't
      // logged in, so we don't notify in that case.
      return
    }

    console.error(error)
    if (error.context && error.error) {
      sentryException({
        name: error.context,
        message: error.error,
      })
    }
    else {
      // for some reason, error.context and error.error seem to be undefined for some high volume alerts.
      // lets see if this gets us any details...
      sentryException({ name: error.toString(), message: error.toString() })
    }
  }

  async #initLogin (): Promise<OidcUser | null | undefined> {
    // TODO: Maybe move this to an event. I'm not sure what should
    // happen if we dynamically turn login off. But it would make
    // sense if we were prepared to trigger a logout or something
    // like that.
    if (!this.#useLogin()) {
      return
    }

    return this.#store.dispatch('authenticateOidcSilent')
      .catch(this.#handleOidcError)
  }

  /**
   * Launches the OIDC sign in/sign up workflow. The parameters are:
   *
   * - signup: Initialize the login widget in "Sign Up" mode.
   * - callbackAction: An action to be dispatched after the login workflow is
   *   complete. (This sends the "login.callbackAction" event on the Event Bus.)
   */
  login ({
    signup,
    callbackAction,
    client,
    site,
    clientDisplay,
    siteDisplay,
    clientLogo,
  }: LoginFunctionParams = {}) {
    this.#logAnalyticsEvent?.('Login Attempt', {
      'event_category': 'PayHub',
    })

    if (!client || !site) {
      ;({
        client,
        site,
        clientDisplay,
        siteDisplay,
        clientLogo,
      } = this.#getClientInfo())
    }

    // Special directives and actions after login
    if (callbackAction) {
      this.#stashCallbackAction(callbackAction)
    }

    // discover the user's language preference
    const locale = storage.getItem('payhubDefaultLocale')

    const extraQueryParams: {
      client_site: string
      client_display: string | undefined
      site_display: string | undefined
      origin_url: string
      display_logo_url: string | undefined
      ui_locales?: string
      action?: string
    } = {
      /* eslint-disable camelcase */
      client_site: `${client}/${site}`,
      client_display: clientDisplay,
      site_display: siteDisplay,
      origin_url: window.location.href,
      display_logo_url: clientLogo,
      /* eslint-enable camelcase */
    }
    if (locale) {
      // eslint-disable-next-line camelcase
      extraQueryParams.ui_locales = locale
    }
    if (signup) {
      extraQueryParams.action = 'signup'
    }

    this.#store.dispatch('authenticateOidc', {
      redirectPath: window.location.href,
      options: {
        extraQueryParams,
      },
    })
  }

  async logout (redirectUri) {
    const user = await this.#store.dispatch('getOidcUser')

    // id_token not acccessible: user data removed or user is already logged out
    if (!user?.id_token) {
      // try to re-fetch user
      try {
        await this.#store.dispatch('authenticateOidcSilent')
      }
      // if re-fetch fails, we assume user is already logged out
      catch (error) {
        console.warn('could not get user - skipping provider logout')
        window.location.reload() // we need to make sure page reloads to e.g. stop showing user data on page
        return
      }
    }
    this.#store.dispatch('signOutOidc', {
      // May be null
      // eslint-disable-next-line camelcase
      post_logout_redirect_uri: redirectUri || window.location.href,
    })
  }

  getPostLoginRedirect () {
    return this.#unstashPostLoginRedirect()
  }

  #unstashPostLoginRedirect (): string | undefined {
    const redirect = storage.getItem(postLoginRedirectKey)
    if (redirect) {
      storage.removeItem(postLoginRedirectKey)
      return redirect
    }
  }

  #callbackActionsKey = 'callbackActions'

  #stashCallbackAction (action: CallbackActionType) {
    const actions = this.#unstashCallbackActions()
    storage.setItem(this.#callbackActionsKey, JSON.stringify([...actions, action]))
  }

  #unstashCallbackActions () {
    let actions = storage.getItem(this.#callbackActionsKey)
    if (!actions) {
      return []
    }

    storage.removeItem(this.#callbackActionsKey)

    actions = JSON.parse(actions)
    if (!Array.isArray(actions)) {
      console.error(`Found unexpected "${this.#callbackActionsKey}" object in localStorage; ignoring`)
      return []
    }

    return actions
  }

  async handleCallbackActions () {
    await this.authPromise
    if (!user.loggedIn) return

    const actions = this.#unstashCallbackActions()
    this.#bus.$emit('login.callbackActions', actions)
    for (const action of actions) {
      this.#bus.$emit('login.callbackAction', { user, action })
    }

    // Emitting this will update the language to the user's saved preference
    this.#bus.$emit('login.userLoaded', user)
  }
}
